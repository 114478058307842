import { Dialog, Button, Typography } from '@mui/material';
import { CONSTANTS } from 'utils/constants';

const marginSize = 48;

const modalContainerStyle = {
  margin: `${marginSize}px`,
  backgroundColor: 'white'
};

const ParamDetailsModal = ({
  open,
  onClose,
  param,
  setParam,
  handleParamUpdate
}: {
  open: any;
  onClose: any;
  param: any;
  setParam: any;
  handleParamUpdate: any;
}) => {
  return (
    <>
      <Dialog open={open} onClose={onClose}>
        {param ? (
          <div style={modalContainerStyle} className='user-details'>
            <Typography
              variant='h2'
              color='blue.900'
              style={{ marginBottom: 15 }}
            >
              Parameter Details:
            </Typography>
            <div>
              ID: <span>{param.id}</span>
            </div>
            <div>
              Category: <span>{param.category}</span>
            </div>
            <div>
              Parameter Name: <span>{param.parameterDesc}</span>
            </div>
            <div>
              Location:{' '}
              <span>
                {CONSTANTS.LOCATION_ACRONYM_TO_FULL_FORM_MAP[param.location]}
              </span>
            </div>
            <div>
              Unit: <span>{param.unit}</span>
            </div>
            <div>
              No of Decimal places: <span>{param.noOfDecimalPlaces}</span>
            </div>
            <div>
              Is Parameter data free? <span>{param.isFree ? 'Yes' : 'No'}</span>
            </div>
            <div
              style={{
                display: 'flex',
                margin: 20
              }}
            >
              <Button
                type='button'
                variant='secondary'
                color='dark'
                onClick={() => {
                  onClose();
                }}
                style={{ marginRight: 15 }}
              >
                Close
              </Button>
              <Button
                type='button'
                variant='primary'
                color='dark'
                onClick={() => {
                  handleParamUpdate({
                    id: param.id,
                    isFree: !param.isFree
                  });
                }}
              >
                {param.isFree ? 'Revoke Free Access' : 'Set Free Access'}
              </Button>
            </div>
          </div>
        ) : null}
      </Dialog>
    </>
  );
};

export default ParamDetailsModal;
