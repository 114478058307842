import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import DataTable from 'react-data-table-component';
import Cookies from 'universal-cookie';
import { Button } from '@mui/material';

import { fetchParams } from 'utils/api';
import CustomContainer from 'components/CustomContainer';
import { CONSTANTS } from 'utils/constants';

const customStyles = {
  headRow: {
    style: {
      color: 'white',
      backgroundColor: '#6699CC' //blue
    }
  },
  headCells: {
    style: {
      fontSize: '16px',
      fontWeight: 'bold',
      border: '1px solid #ddd', // add border to header cells
      justifyContent: 'center'
    }
  },
  rows: {
    style: {
      minHeight: '56px',
      '&:nth-child(even)': {
        backgroundColor: '#FFFFFF' //white
      },
      '&:nth-child(odd)': {
        backgroundColor: '#D0D5E8' //grey
      },
      border: '1px solid #ddd' // add border to rows
    }
  },
  cells: {
    style: {
      fontSize: '14px',
      paddingLeft: '8px',
      paddingRight: '8px',
      width: '20%',
      borderRight: '1px solid #ddd', // add right border to cells
      justifyContent: 'center'
    }
  }
};

export default function ParametersTable(props: any) {
  const [fetchedData, setFetchedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const cookies = new Cookies();
  const [refreshTable, setRefreshTable] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchTableData().then(() => setLoading(false));
  }, [refreshTable]);

  const columns: any = [
    {
      name: 'ID',
      selector: (param: any) => param.id
    },
    {
      name: 'Category',
      selector: (param: any) => param.category
    },
    {
      name: 'Name',
      selector: (param: any) => param.parameterDesc
    },
    {
      name: 'Location',
      selector: (param: any) =>
        CONSTANTS.LOCATION_ACRONYM_TO_FULL_FORM_MAP[param.location]
    },
    {
      name: '',
      cell: (param: any) => (
        <Button
          type='button'
          variant='primary'
          color='dark'
          onClick={() => {
            props.setParam(param);
          }}
        >
          View
        </Button>
      )
    }
  ];

  const fetchTableData = async () => {
    try {
      const params = await fetchParams();
      setFetchedData(params);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <CustomContainer>
      <div style={{ height: 32 }} />
      <Box sx={{ width: '100%' }}>
        <DataTable
          title=''
          columns={[...columns]}
          data={fetchedData}
          progressPending={loading}
          pagination
          customStyles={customStyles}
        />
      </Box>
    </CustomContainer>
  );
}
