export const CONSTANTS: any = {
  ROLE: {
    ROLE_USER: 'ROLE_USER',
    ROLE_ADMIN: 'ROLE_ADMIN',
    ROLE_BUOY_MAINTAINER: 'ROLE_BUOY_MAINTAINER',
    ROLE_COLLABORATOR: 'ROLE_COLLABORATOR'
  },
  STATUS: {
    APPROVED: 'APPROVED',
    PENDING_REVIEW: 'PENDING_REVIEW',
    REJECTED: 'REJECTED',
    DEPRECATED: 'DEPRECATED'
  },
  ACTION: {
    APPROVE: 'APPROVE',
    REJECT: 'REJECT'
  },
  LOCATION_ACRONYM_TO_FULL_FORM_MAP: {
    SJI: 'St. John’s Island',
    RLH: 'Raffles Lighthouse',
    UBN: 'Pulau Ubin',
    HANTU: 'Hantu',
    KUSU: 'Kusu',
    WEST_UBIN: 'West Ubin'
  },
  LOCATION_FULL_FORM_TO_ACRONYM_MAP: {
    'St. John’s Island': 'SJI',
    'Raffles Lighthouse': 'RLH',
    'Pulau Ubin': 'UBN',
    Hantu: 'HANTU',
    Kusu: 'KUSU',
    'West Ubin': 'WEST_UBIN'
  },
  INTERVALS: {
    '5m': '5m',
    '30m': '30m',
    '1h': '1h',
    '12h': '12h',
    '1d': '1d',
    '3d': '3d',
    '1w': '1w',
    '1mo': '1mo'
  }
};
